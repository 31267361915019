import React from 'react';
import { CurrentUser } from 'Roblox';
import { Loading } from 'react-style-guide';
import EventsListContainer from './components/EventsListContainer';
import { TGetGameDetails } from '../common/types/bedev1Types';
import useEventDetailsForUniverseId from './hooks/useEventDetailsForUniverseId';

type TGameDetailsVirtualEventsSectionProps = {
  universeId: string;
  gameDetails: TGetGameDetails | undefined;
  isEventsSectionRedesignEnabled: boolean;
};

const GameDetailsVirtualEventsSection = ({
  universeId,
  gameDetails,
  isEventsSectionRedesignEnabled
}: TGameDetailsVirtualEventsSectionProps): JSX.Element => {
  const { eventDetails, hasError } = useEventDetailsForUniverseId(universeId);

  if (hasError || !CurrentUser.isAuthenticated) {
    // show nothing, as the user can use the rest of the page as normal
    return <React.Fragment />;
  }

  if (eventDetails === undefined || gameDetails === undefined) {
    return <Loading />;
  }

  if (eventDetails?.length > 0 && gameDetails !== undefined) {
    return (
      <div className='virtual-event-game-details-container'>
        <EventsListContainer
          eventList={eventDetails}
          universeDetails={gameDetails}
          isEventsSectionRedesignEnabled={isEventsSectionRedesignEnabled}
        />
      </div>
    );
  }

  return <React.Fragment />;
};

export default GameDetailsVirtualEventsSection;
