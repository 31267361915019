import React from 'react';
import '../../../../css/gameDetails/_survey.scss';
import { Button } from 'react-style-guide';

export type ResponseOptionButtonProps = {
  answerText: string;
  onClick: () => void;
};

const ResponseOptionButton: React.FC<ResponseOptionButtonProps> = ({ answerText, onClick }) => {
  return (
    <Button onClick={onClick} variant={Button.variants.cta} size={Button.sizes.small}>
      {answerText}
    </Button>
  );
};
export default ResponseOptionButton;
