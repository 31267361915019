import { useState, useEffect } from 'react';
import { CurrentUser } from 'Roblox';
import { getEventsForUniverseId, VirtualEvent } from '../services/services';

const useEventDetailsForUniverseId = (
  universeId: string
): {
  eventDetails: VirtualEvent[] | undefined;
  hasError: boolean;
} => {
  const [eventDetails, setEventsDetails] = useState<VirtualEvent[] | undefined>(undefined);

  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    if (!CurrentUser.isAuthenticated) {
      return;
    }

    setHasError(false);
    getEventsForUniverseId(universeId)
      .then(eventsData => {
        setEventsDetails(eventsData);
      })
      .catch(() => {
        setHasError(true);
      });
  }, [universeId]);

  return { eventDetails, hasError };
};

export default useEventDetailsForUniverseId;
