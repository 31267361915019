import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { Link, createSystemFeedback } from 'react-style-guide';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import {
  getTotalRsvpCounters,
  VirtualEvent,
  RSVP_STATUS,
  postRsvpStatus
} from '../services/services';
import {
  getLocalizedDateString,
  tryParseDate,
  getShortenedNumber,
  getEventLivenessState,
  generateSessionId,
  joinExperience
} from '../utils/utils';
import { translation } from '../translation.config';
import AnalyticsEvents from '../utils/analytics';
import {
  getTranslationStringForKeyWithFallback,
  separator,
  EVENT_LIVENESS_STATE
} from '../constants/constants';
import '../../../../css/gameDetailsVirtualEvents/eventListItem.scss';
import { TGetGameDetails } from '../../common/types/bedev1Types';
import useEventTileImpressionTracker from '../hooks/useEventTileImpressionTracker';

type EventListItemProps = {
  eventItem: VirtualEvent;
  universeDetails: TGetGameDetails;
  playabilityStatus: string | undefined;
} & WithTranslationsProps;

const [SystemFeedback, systemFeedbackService] = createSystemFeedback();

const EventListItem = ({
  eventItem,
  universeDetails,
  playabilityStatus,
  translate
}: EventListItemProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const [totalRsvps, setTotalRsvps] = useState(0);
  const [userHasRsvpd, setUserHasRsvpd] = useState(false);

  const eventTileRef = useRef<HTMLLIElement>(null);

  const isEventLive =
    getEventLivenessState(
      tryParseDate(eventItem.eventTime.startUtc),
      tryParseDate(eventItem.eventTime.endUtc)
    ) === EVENT_LIVENESS_STATE.LIVE;

  const getTextForBtn = useCallback((): string => {
    if (isEventLive) {
      return getTranslationStringForKeyWithFallback(translate, 'joinEvent');
    }
    if (userHasRsvpd) {
      return getTranslationStringForKeyWithFallback(translate, 'unfollowEvent');
    }
    return getTranslationStringForKeyWithFallback(translate, 'NotifyMe');
  }, [isEventLive, translate, userHasRsvpd]);

  const getClassForBtn = useCallback((): string => {
    if (isEventLive) {
      return 'btn-growth-md';
    }
    if (userHasRsvpd) {
      return 'btn-control-md';
    }
    return 'btn-primary-md';
  }, [isEventLive, userHasRsvpd]);

  const joinEventBtnClicked = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      const sessionId = generateSessionId();
      AnalyticsEvents.sendVirtualEventJoinedFromExperienceDetailsPageEvent(
        eventItem.id,
        eventItem.universeId,
        sessionId,
        totalRsvps
      );
      try {
        if (universeDetails.rootPlaceId !== undefined) {
          joinExperience(universeDetails.rootPlaceId, sessionId, eventItem.id);
        }
        // eslint-disable-next-line no-empty
      } catch {}
    },
    [eventItem.id, eventItem.universeId, universeDetails, totalRsvps]
  );

  const setRsvpToGoing = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      AnalyticsEvents.sendVirtualEventRSVPFromExpDetailsEvent(
        eventItem.id,
        eventItem.universeId,
        RSVP_STATUS.GOING,
        totalRsvps
      );
      try {
        await postRsvpStatus(eventItem.id, RSVP_STATUS.GOING);
        setTotalRsvps(t => t + 1);
        setUserHasRsvpd(true);
      } catch {
        systemFeedbackService.warning(
          getTranslationStringForKeyWithFallback(translate, 'networkError')
        );
      }
    },
    [eventItem.id, eventItem.universeId, totalRsvps, translate]
  );

  const setRsvpToNotGoing = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      AnalyticsEvents.sendVirtualEventRSVPFromExpDetailsEvent(
        eventItem.id,
        eventItem.universeId,
        RSVP_STATUS.NOT_GOING,
        totalRsvps
      );
      try {
        await postRsvpStatus(eventItem.id, RSVP_STATUS.NOT_GOING);
        setTotalRsvps(t => Math.max(t - 1, 0));
        setUserHasRsvpd(false);
      } catch {
        systemFeedbackService.warning(
          getTranslationStringForKeyWithFallback(translate, 'networkError')
        );
      }
    },
    [eventItem.id, eventItem.universeId, totalRsvps, translate]
  );

  const isPlayButtonEnabled = useMemo(() => {
    return playabilityStatus === 'Playable';
  }, [playabilityStatus]);

  const getActionForBtn = useCallback(() => {
    if (isEventLive) {
      return joinEventBtnClicked;
    }
    if (userHasRsvpd) {
      return setRsvpToNotGoing;
    }
    return setRsvpToGoing;
  }, [isEventLive, joinEventBtnClicked, setRsvpToGoing, setRsvpToNotGoing, userHasRsvpd]);

  const trackEventDetailsPageVisit = useCallback(() => {
    AnalyticsEvents.sendEventDetailsPageVisitFromExpDetailsEvent(
      eventItem.id,
      eventItem.universeId
    );
  }, [eventItem.id, eventItem.universeId]);

  useEventTileImpressionTracker(eventTileRef, eventItem.id, eventItem.universeId);

  useEffect(() => {
    const fetchRsvpsForEvent = async () => {
      try {
        const totalRsvpsRes = await getTotalRsvpCounters(eventItem.id);
        setTotalRsvps(totalRsvpsRes.counters.going);
        setIsLoading(false);
      } catch {
        setTotalRsvps(0);
        setIsLoading(false);
      }
    };
    // TODO https://jira.rbx.com/browse/EN-1852 test this works once backend has added support for this field
    setUserHasRsvpd(eventItem.userRsvpStatus === RSVP_STATUS.GOING);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchRsvpsForEvent();
  }, [eventItem.id, eventItem.universeId, eventItem.userRsvpStatus, isEventLive]);

  return (
    <li className='stack-row' ref={eventTileRef}>
      <Link
        className='clickable-event-list-item'
        aria-label={eventItem.title}
        onClick={trackEventDetailsPageVisit}
        url={`https://${window.location.hostname}/events/${eventItem.id}`}>
        <div className='clickable-event-list-item-contents'>
          <div className='event-title font-header-2'>{eventItem.title}</div>
          <div className='event-subtitle-container'>
            <div className='event-subtitle-item small-oneline text'>
              {getLocalizedDateString(tryParseDate(eventItem.eventTime.startUtc)).toUpperCase()}
            </div>
            {!isLoading && (totalRsvps > 0 || universeDetails.playing > 0) && (
              <div className='event-subtitle-item small-oneline text'>{separator}</div>
            )}
            {totalRsvps > 0 && (
              // using React.Fragment for flexbox leveling to work
              <React.Fragment>
                <div className='icon-override favorited-icon' />
                <div className='event-subtitle-item small-oneline text'>
                  {getShortenedNumber(totalRsvps, translate)}
                </div>
              </React.Fragment>
            )}
            {isEventLive && universeDetails.playing > 0 && (
              <React.Fragment>
                <div className='icon-override player-icon' />
                <div className='event-subtitle-item small-oneline text'>
                  {getShortenedNumber(universeDetails.playing, translate)}
                </div>
              </React.Fragment>
            )}
          </div>
          <div className='event-description-container'>
            <p className='event-description small text'>{eventItem.description}</p>
          </div>
          <button
            type='button'
            disabled={!isPlayButtonEnabled && isEventLive}
            aria-label={!isPlayButtonEnabled && isEventLive ? playabilityStatus : getTextForBtn()}
            onClick={getActionForBtn()}
            className={`notify-button btn-full-width ${getClassForBtn()}`}>
            {getTextForBtn()}
          </button>
        </div>
        <SystemFeedback />
      </Link>
    </li>
  );
};

export default withTranslations(EventListItem, translation);
