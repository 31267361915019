import { useCallback, useEffect, useMemo, useState } from 'react';
import { TSystemFeedbackService } from 'react-style-guide';
import { TranslateFunction } from 'react-utilities';
import AnalyticsEvents from '../utils/analytics';
import {
  getTotalRsvpCounters,
  postRsvpStatus,
  RSVP_STATUS,
  VirtualEvent
} from '../services/services';
import { getTranslationStringForKeyWithFallback } from '../constants/constants';
import { getLocalizedDateString, tryParseDate } from '../utils/utils';

const useEventRsvpStatus = (
  eventItem: VirtualEvent,
  systemFeedbackService: TSystemFeedbackService,
  translate: TranslateFunction
): {
  userHasRsvpd: boolean;
  totalRsvps: number;
  handleToggleRsvpClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
} => {
  const [userHasRsvpd, setUserHasRsvpd] = useState(eventItem.userRsvpStatus === RSVP_STATUS.GOING);

  const [totalRsvps, setTotalRsvps] = useState<number>(0);

  useEffect(() => {
    getTotalRsvpCounters(eventItem.id)
      .then(response => {
        setTotalRsvps(response.counters.going);
      })
      .catch(() => {
        // Non-blocking, as total RSVP count is only used for telemetry
      });
  }, [eventItem.id]);

  const rsvpSuccessMessage = useMemo(() => {
    const dateString = getLocalizedDateString(tryParseDate(eventItem.eventTime.startUtc));
    return getTranslationStringForKeyWithFallback(translate, 'reminderSetForEventTime', {
      eventName: eventItem.title,
      eventTime: dateString
    });
  }, [eventItem.eventTime.startUtc, eventItem.title, translate]);

  const handleToggleRsvpClick = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      const newRsvpStatus = userHasRsvpd ? RSVP_STATUS.NOT_GOING : RSVP_STATUS.GOING;

      e.preventDefault();
      e.stopPropagation();
      AnalyticsEvents.sendVirtualEventRSVPFromExpDetailsEvent(
        eventItem.id,
        eventItem.universeId,
        newRsvpStatus,
        totalRsvps
      );
      try {
        await postRsvpStatus(eventItem.id, newRsvpStatus);
        setUserHasRsvpd(newRsvpStatus === RSVP_STATUS.GOING);

        if (newRsvpStatus === RSVP_STATUS.GOING) {
          systemFeedbackService.success(rsvpSuccessMessage);
        }
      } catch {
        systemFeedbackService.warning(
          getTranslationStringForKeyWithFallback(translate, 'networkError')
        );
      }
    },
    [eventItem, rsvpSuccessMessage, systemFeedbackService, translate, userHasRsvpd, totalRsvps]
  );

  return { userHasRsvpd, totalRsvps, handleToggleRsvpClick };
};

export default useEventRsvpStatus;
