import React, { useEffect, useState } from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { carouselTranslationConfig } from '../translation.config';
import { TCarouselItem, TAssetType } from '../types/carouselTypes';
import CarouselComponent from '../components/Carousel';
import carouselService from '../services/carouselService';

export type TCarouselProps = {
  placeId: string;
  universeId: string;
  delay: number;
};

export const Carousel = ({
  translate,
  placeId,
  universeId,
  delay
}: TCarouselProps & WithTranslationsProps): JSX.Element => {
  const [assets, setAssets] = useState<TCarouselItem[] | undefined>(undefined);

  useEffect(() => {
    const fetchAssets = async () => {
      try {
        const carouselItems = await carouselService.getUniverseAssetIds(universeId);
        setAssets(carouselItems);
      } catch {
        setAssets([]);
      }
    };

    // eslint-disable-next-line no-void
    void fetchAssets();
  }, [universeId]);

  if (assets === undefined) {
    return <div className='shimmer' data-testid='loading' />;
  }

  if (assets.length === 0) {
    return (
      <CarouselComponent
        translate={translate}
        delay={delay}
        items={[
          {
            type: TAssetType.Place,
            assetId: parseInt(placeId, 10)
          }
        ]}
      />
    );
  }

  return <CarouselComponent translate={translate} delay={delay} items={assets} />;
};

export default withTranslations(Carousel, carouselTranslationConfig);
