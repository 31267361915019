import React, { useMemo } from 'react';
import { TranslateFunction } from 'react-utilities';
import { getTranslationStringForKeyWithFallback } from '../constants/constants';
import { getLocalizedDateString, tryParseDate } from '../utils/utils';
import { GameTileTextPill } from '../../common/components/GameTileOverlayPill';

type TExperienceEventTileOverlayPillProps = {
  isEventLive: boolean;
  eventStartTimeUtc: string;
  translate: TranslateFunction;
};

const ExperienceEventTileOverlayPill = ({
  isEventLive,
  eventStartTimeUtc,
  translate
}: TExperienceEventTileOverlayPillProps): JSX.Element => {
  const tileBadgeText = useMemo(() => {
    if (isEventLive) {
      return getTranslationStringForKeyWithFallback(translate, 'happeningNow');
    }

    return getLocalizedDateString(tryParseDate(eventStartTimeUtc));
  }, [isEventLive, eventStartTimeUtc, translate]);

  return <GameTileTextPill text={tileBadgeText} />;
};

export default ExperienceEventTileOverlayPill;
