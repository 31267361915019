import React, { useEffect, useState } from 'react';
import { TranslateFunction } from 'react-utilities';
import { Loading } from 'react-style-guide';
import { TGetGameDetails, TGetUniverseVoiceStatus } from '../../common/types/bedev1Types';
import bedev1Services from '../../common/services/bedev1Services';
import { FeatureGameDetails } from '../../common/constants/translationConstants';
import GameDescription from './GameDescription';
import GameDescriptionTable from './GameDescriptionTable';
import GameDescriptionFooter from './GameDescriptionFooter';
import AgeRecommendationTitle from '../../gameGuidelines/containers/AgeRecommendationTitle';
import '../../../../css/gameDetails/_description.scss';
import CommunityLinkDisplay from '../../gameCommunity/containers/CommunityLinkDisplay';

export type TGameDescriptionSectionProps = {
  universeId: string;
  placeId: string;
  gameDetails: TGetGameDetails | undefined;
  translate: TranslateFunction;
};

export const GameDescriptionSection = ({
  universeId,
  placeId,
  gameDetails,
  translate
}: TGameDescriptionSectionProps): JSX.Element => {
  const [universeVoiceStatus, setUniverseVoiceStatus] = useState<
    TGetUniverseVoiceStatus | undefined
  >(undefined);

  useEffect(() => {
    const fetchVoiceStatus = () => {
      bedev1Services
        .getVoiceStatus(universeId)
        .then(response => setUniverseVoiceStatus(response))
        .catch(() =>
          setUniverseVoiceStatus({
            isUniverseEnabledForVoice: false,
            isUniverseEnabledForAvatarVideo: false
          })
        );
    };

    fetchVoiceStatus();
  }, [universeId]);

  if (gameDetails === undefined || universeVoiceStatus === undefined) {
    return <Loading />;
  }

  return (
    <div className='game-description-container'>
      <div className='container-header'>
        <h2>{translate(FeatureGameDetails.HeadingDescription)}</h2>
      </div>

      <GameDescription
        name={gameDetails.name}
        sourceName={gameDetails.sourceName}
        description={gameDetails.description}
        sourceDescription={gameDetails.sourceDescription}
        translate={translate}
      />

      <div
        id='game-age-recommendation-details-container'
        className='game-age-recommendation-details-container'>
        <AgeRecommendationTitle isDisplayAgeRecommendationDetails />
      </div>

      <div className='game-community-link-display-container'>
        <CommunityLinkDisplay />
      </div>

      <GameDescriptionTable
        gameDetails={gameDetails}
        universeVoiceStatus={universeVoiceStatus}
        translate={translate}
      />

      <GameDescriptionFooter
        placeId={placeId}
        name={gameDetails.name}
        copyingAllowed={gameDetails.copyingAllowed}
        translate={translate}
      />
    </div>
  );
};

export default GameDescriptionSection;
