import React from 'react';
import { TranslateFunction } from 'react-utilities';
import { Link } from 'react-style-guide';
import { buildReportAbuseUrl } from '../../common/utils/browserUtils';
import { FeatureGameDetails } from '../../common/constants/translationConstants';

type TGameDescriptionFooterProps = {
  placeId: string;
  name: string;
  copyingAllowed: boolean;
  translate: TranslateFunction;
};

const GameDescriptionFooter = ({
  placeId,
  name,
  copyingAllowed,
  translate
}: TGameDescriptionFooterProps): JSX.Element => {
  return (
    <div className='game-description-footer'>
      {copyingAllowed && (
        <p className='text-pastname'>{translate(FeatureGameDetails.LabelPlaceCopyingAllowed)}</p>
      )}
      <Link className='text-report' url={buildReportAbuseUrl(placeId, name)}>
        {translate(FeatureGameDetails.LabelReportAbuse)}
      </Link>
    </div>
  );
};

export default GameDescriptionFooter;
