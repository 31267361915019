import React from 'react';
import { TSurveyIcon } from '../../common/types/bedev2Types';

const surveyIconMap: Record<TSurveyIcon, string> = {
  helpIcon: 'icon-moreinfo'
};

function isTSurveyIcon(iconType: any): iconType is TSurveyIcon {
  return Object.values(TSurveyIcon).includes(iconType);
}

export const SurveyLeadingIcon = ({
  iconType
}: {
  iconType: string | undefined;
}): JSX.Element | null => {
  if (iconType && isTSurveyIcon(iconType) && surveyIconMap[iconType]) {
    return (
      <div className='leading-icon-container'>
        <span className={surveyIconMap[iconType as TSurveyIcon]} />
      </div>
    );
  }
  return null;
};

export default SurveyLeadingIcon;
