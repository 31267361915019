import { ShareLinks } from 'Roblox';
import { ready } from 'core-utilities';
import React from 'react';
import { render } from 'react-dom';
import InviteLinkInvalidModal from './components/InviteLinkInvalidModal';
import Carousel from './containers/Carousel';
import PlayButton from './containers/PlayButton';
import AboutTab from './containers/AboutTab';
import metadataConstants from './constants/metadataConstants';
import carouselConstants from './constants/carouselConstants';
import playButtonConstants from './constants/playButtonConstants';
import aboutTabConstants from './constants/aboutTabConstants';

import '../../../css/gameDetails/gameDetails.scss';
import inviteLinkInvalidModalConstants from './constants/inviteLinkInvalidModalConstants';

ready(() => {
  if (carouselConstants.gameDetailsCarouselContainer()) {
    const { universeId = '', placeId = '' } = metadataConstants.metadataData();
    render(
      <Carousel
        universeId={universeId}
        placeId={placeId}
        delay={carouselConstants.carouselConfigs.delay}
      />,
      carouselConstants.gameDetailsCarouselContainer()
    );
  }

  if (playButtonConstants.gameDetailsPlayButtonContainer()) {
    render(<PlayButton />, playButtonConstants.gameDetailsPlayButtonContainer());
  }

  if (aboutTabConstants.gameDetailsAboutTabContainer()) {
    render(<AboutTab />, aboutTabConstants.gameDetailsAboutTabContainer());
  }

  const {
    experienceInviteLinkId = '',
    experienceInviteStatus = ''
  } = metadataConstants.metadataData();
  if (
    experienceInviteLinkId &&
    (experienceInviteStatus === ShareLinks.ExperienceInviteStatus.INVITER_NOT_IN_EXPERIENCE ||
      experienceInviteStatus === ShareLinks.ExperienceInviteStatus.EXPIRED) &&
    inviteLinkInvalidModalConstants.gameDetailsInviteLinkInvalidModalContainer()
  ) {
    const { universeId = '', placeId = '' } = metadataConstants.metadataData();
    render(
      <InviteLinkInvalidModal
        linkId={experienceInviteLinkId}
        linkStatus={experienceInviteStatus}
        placeId={placeId}
        universeId={universeId}
      />,
      inviteLinkInvalidModalConstants.gameDetailsInviteLinkInvalidModalContainer()
    );
  }
});
