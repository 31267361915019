import React from 'react';
import { regex } from 'core-utilities';

type TGameDescriptionTextProps = {
  descriptionText: string | undefined;
};

const GameDescriptionText = ({ descriptionText }: TGameDescriptionTextProps): JSX.Element => {
  return (
    <pre className='text game-description'>
      {descriptionText?.split(regex.url).map((text, index) => {
        if (text.search(regex.url) > -1) {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <a key={index} data-testid='game-description-link' className='text-link' href={text}>
              {text}
            </a>
          );
        }
        return text;
      })}
    </pre>
  );
};

export default GameDescriptionText;
