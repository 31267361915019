import { TranslateFunction } from 'react-utilities';

// Most of these are strings which will be moved to transaltions in a subsequent PR
const translationConstants = {
  millionShorthandIdentifier: { translationKey: 'millionShorthandIdentifier', fallback: 'M' },
  billionShorthandIdentifier: { translationKey: 'billionShorthandIdentifier', fallback: 'B' },
  thousandShorthandIdentifier: { translationKey: 'thousandShorthandIdentifier', fallback: 'K' },

  eventsTitle: { translationKey: 'EventsListTitle', fallback: 'Events' },
  seeMore: { translationKey: 'SeeMore', fallback: 'See More' },

  joinEvent: { translationKey: 'JoinEvent', fallback: 'Join Event' },
  unfollowEvent: { translationKey: 'UnfollowEvent', fallback: 'Unfollow Event' },
  NotifyMe: { translationKey: 'NotifyMe', fallback: 'Notify Me' },
  networkError: {
    translationKey: 'NetworkError',
    fallback: 'Something went wrong. Please try again later'
  },

  happeningNow: { translationKey: 'Label.HappeningNow', fallback: 'Happening now' },
  reminderSetForEventTime: {
    translationKey: 'Label.ReminderSetForEventTime',
    fallback: 'Reminder for event is set.'
  }
} as const;

export const separator = `•`;

export const getTranslationStringForKeyWithFallback = (
  translate: TranslateFunction,
  key: keyof typeof translationConstants,
  optionalArgs?: any
): string => {
  return (
    translate(translationConstants[key].translationKey, optionalArgs) ||
    translationConstants[key].fallback
  );
};

export const EVENT_LIVENESS_STATE = {
  UPCOMING: 'UPCOMING',
  LIVE: 'LIVE',
  PAST: 'PAST'
};

export const eventTileImpressionVisibilityThreshold = 0.5;

export default translationConstants;
