import React, { useEffect, useState } from 'react';
import { SimpleModal } from 'react-style-guide';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { eventStreamService, playGameService } from 'core-roblox-utilities';
import { ShareLinks } from 'Roblox';
import { FeatureSocialShare } from '../../common/constants/translationConstants';
import { inviteLinkInvalidModalConfig } from '../translation.config';
import bedev1Services from '../../common/services/bedev1Services';
import { TGetGameDetails } from '../../common/types/bedev1Types';
import {
  buildInviteLinkInvalidModalEvent,
  buildInviteLinkInvalidModalLaunchGameEvent,
  joinButtonName,
  cancelButtonName
} from './inviteLinkInvalidModalEventStreamUtils';
import { inviteLinkInvalidModalTranslationMap } from '../constants/inviteLinkInvalidModalConstants';

const { ExperienceInviteStatus } = ShareLinks;

export type TInviteLinkInvalidModalProps = {
  linkId: string;
  linkStatus: Exclude<
    typeof ExperienceInviteStatus[keyof typeof ExperienceInviteStatus],
    typeof ExperienceInviteStatus.VALID
  >;
  placeId: string;
  universeId: string;
};

export const InviteLinkInvalidModal = ({
  translate,
  linkId,
  linkStatus,
  placeId,
  universeId
}: TInviteLinkInvalidModalProps & WithTranslationsProps): JSX.Element => {
  const [showModal, setShowModal] = useState(true);
  const [gameDetails, setGameDetails] = useState<TGetGameDetails | undefined>(undefined);

  useEffect(() => {
    bedev1Services.getGameDetails(universeId).then(
      (response: TGetGameDetails) => {
        setGameDetails(response);
      },
      () => false
    );
  }, [universeId]);

  const modalBody = (
    <p>
      {translate(inviteLinkInvalidModalTranslationMap[linkStatus].Description, {
        experienceName: gameDetails?.name
      })}
    </p>
  );

  const closeModal = () => setShowModal(false);

  const joinGame = () => {
    playGameService.launchGame(
      playGameService.buildPlayGameProperties(placeId, placeId),
      buildInviteLinkInvalidModalLaunchGameEvent(placeId, linkId, linkStatus)
    );

    const event = buildInviteLinkInvalidModalEvent(joinButtonName, linkId, linkStatus);
    eventStreamService.sendEventWithTarget(event.type, event.context, event.params);

    closeModal();
  };

  const neutralClick = () => {
    const event = buildInviteLinkInvalidModalEvent(cancelButtonName, linkId, linkStatus);
    eventStreamService.sendEventWithTarget(event.type, event.context, event.params);

    closeModal();
  };

  return (
    <SimpleModal
      show={showModal}
      title={translate(inviteLinkInvalidModalTranslationMap[linkStatus].Header)}
      body={gameDetails && modalBody}
      loading={!gameDetails}
      actionButtonShow
      actionButtonText={translate(FeatureSocialShare.LabelPlay)}
      neutralButtonText={translate(FeatureSocialShare.LabelCancel)}
      onAction={joinGame}
      onClose={closeModal}
      onNeutral={neutralClick}
    />
  );
};

export default withTranslations(InviteLinkInvalidModal, inviteLinkInvalidModalConfig);
